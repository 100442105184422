















import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { reserveerApi } from "@/lib/backend";

export default defineComponent({
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data } = useQuery({
			queryKey: ["member", props.memberId, "personal-trainings"] as const,
			queryFn: async (context) =>
				await reserveerApi.persoonlijkeTrainingen
					.trainingsProgrammas(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
		});

		const current = computed(() => data.value?.at(-1));

		return { status, data, current };
	},
});
